import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  /* The device with borders */
  smartphone: {
    position: "relative",
    width: "360px",
    height: "640px",
    margin: "auto",
    border: "16px black solid",
    borderTopWidth: "60px",
    borderBottomWidth: "60px",
    borderRadius: "36px",
    /* The horizontal line on the top of the device */
    "&::before": {
      content: '""',
      display: "block",
      width: "60px",
      height: "5px",
      position: "absolute",
      top: "-30px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#333",
      borderRadius: "10px",
    } /* The circle on the bottom of the device */,
    "&::after": {
      content: '""',
      display: "block",
      width: "35px",
      height: "35px",
      position: "absolute",
      left: "50%",
      bottom: "-65px",
      transform: "translate(-50%, -50%)",
      background: "#333",
      borderRadius: "50%",
    },
  },
  /* The screen (or content) of the device */
}));

const SmartPhone = ({ children, backgroundUrl }) => {
  const classes = useStyles();
  return (
    <div className={classes.smartphone}>
      <div
        className={classes.content}
        style={{
          backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "",
          width: "328px",
          height: "520px",
          padding: "5px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SmartPhone;
