import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Typography,
  CircularProgress,
  makeStyles,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { submitUniverse } from "../constants/functions";
import { getUniverseFormInitialValues } from "../constants/dataBaseFormat";
import FileSelectAndPreview from "../components/FileSelectAndPreview";
import YoutubeView from "../components/YoutubeView";
import { deleteUniverse, fetchGames, fetchUniverses } from "../constants/api";
import IntroductionPreview from "../components/UniversePreview/IntroductionPreview";
import GamePreview from "../components/UniversePreview/GamePreview";

const useStyles = makeStyles(() => ({
  overlay: {
    width: "100vw",
    height: "100vh",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2,
  },
  input: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: 4,
    boxSizing: "border-box",
  },
  title: {
    marginTop: 30,
    marginBottom: 20,
  },
  margin: {
    margin: 5,
  },
}));

const UniverseForm = ({ userId, universe, goBack }) => {
  const classes = useStyles();
  const [loadingText, setLoadingText] = useState(null);
  const [loadingResult, setLoadingResult] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [formValues, setFormValues] = useState(
    getUniverseFormInitialValues(universe)
  );

  const isOwner =
    !universe ||
    userId === universe.createdBy ||
    userId === "706e89ab-2aa8-4315-b59f-c0e6a3043438";

  const onSubmit = async (e) => {
    e && e.preventDefault();
    setLoadingText("Creating mission...");
    const uploadSuccess = await submitUniverse(
      universe ? { id: universe.id, ...formValues } : formValues
    );
    if (uploadSuccess) {
      setLoadingResult("Mission successfully created !");
      fetchUniverses();
      fetchGames();
      goBack();
    } else setLoadingResult("An error occurred during mission creation.");
    setTimeout(() => {
      setLoadingText(null);
      setLoadingResult(null);
    }, 2000);
  };

  const handleChange = (e, key, subKey) => {
    const { value } = e.target;
    console.log({ e, key, value });
    setFormValues((s) => {
      if (subKey) {
        if (s[key]) {
          s[key][subKey] = value;
        } else {
          s[key] = { [subKey]: value };
        }
      } else s[key] = value;
      return { ...s };
    });
  };

  const onFileChoosen = (file, key, subKey) => {
    console.log({ file, key, subKey });
    setFormValues((s) => {
      s[key][subKey] = file === undefined ? "" : file;
      return { ...s };
    });
  };

  const formEntries = Object.entries(formValues);
  console.log({ formEntries, universe });

  return (
    <>
      {showPreview ? (
        <div
          style={{
            display: "block",
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              background: "white",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              type="button"
              className={classes.margin}
              onClick={() => setShowPreview(false)}
              startIcon={<ArrowBackIcon>Back</ArrowBackIcon>}
            >
              Back
            </Button>
            <Typography variant="h5">Introduction preview</Typography>
            <IntroductionPreview universe={universe} formValues={formValues} />
            <Typography variant="h5">In game preview</Typography>
            <GamePreview universe={universe} formValues={formValues} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: showPreview ? "none" : "block",
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {loadingText && !loadingResult && (
            <div className={classes.overlay}>
              <Typography variant="h4" style={{ color: "white" }}>
                {loadingText}
              </Typography>
              <CircularProgress />
            </div>
          )}
          {loadingResult && (
            <div className={classes.overlay}>
              <Typography variant="h4" style={{ color: "white" }}>
                {loadingResult}
              </Typography>
            </div>
          )}
          <Button
            color="primary"
            variant="outlined"
            type="button"
            className={classes.margin}
            onClick={goBack}
            startIcon={<ArrowBackIcon>Back</ArrowBackIcon>}
            style={{ background: "white" }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            className={classes.margin}
            onClick={() => setShowPreview(true)}
            startIcon={<VisibilityIcon>Preview mission</VisibilityIcon>}
          >
            Preview mission
          </Button>
          {universe && isOwner ? (
            <>
              <Button
                onClick={() => {
                  onSubmit();
                }}
                variant="contained"
                color="primary"
                className={classes.margin}
                startIcon={<CloudUploadIcon />}
              >
                Update mission
              </Button>
              <Button
                onClick={() => {
                  deleteUniverse(universe.id);
                  fetchUniverses();
                  fetchGames();
                  goBack();
                }}
                variant="contained"
                color="secondary"
                className={classes.margin}
                startIcon={<DeleteIcon>Delete mission</DeleteIcon>}
                disabled={
                  universe.id === "b4d76a9c-6e97-41ec-92e2-4fed9b821fbe"
                }
              >
                Delete mission
              </Button>
            </>
          ) : null}
          <Typography variant="h4" className={classes.title}>
            {universe ? `Mission: ${universe.title}` : "Create mission"}
          </Typography>

          <form
            onSubmit={onSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paper style={{ padding: 30 }}>
              {formEntries.map(([fieldKey, fieldValue], i) => {
                if (
                  fieldKey === "introImage" ||
                  fieldKey === "introVideo" ||
                  fieldKey === "introAudio"
                )
                  return null;
                if (fieldKey === "introType")
                  return (
                    <Fragment key={i}>
                      {isOwner ? (
                        <div
                          key={`IntroTypeEntry-${i + 1}`}
                          className={classes.row}
                        >
                          <Typography className={classes.inputLabel}>
                            Intro type
                          </Typography>
                          <Select
                            value={fieldValue}
                            onChange={(e) => handleChange(e, "introType")}
                            // disabled={!isOwner}
                            disabled={!!universe && !isOwner}
                          >
                            <MenuItem value={"image"}>Image</MenuItem>
                            <MenuItem value={"audio"}>Audio</MenuItem>
                            <MenuItem value={"video"}>Video</MenuItem>
                          </Select>
                        </div>
                      ) : (
                        <Typography className={classes.inputLabel}>
                          {`Intro ${fieldValue}`}
                        </Typography>
                      )}
                      <div key={`FormEntry-${i + 1}`} className={classes.row}>
                        {(fieldValue === "image" || fieldValue === "audio") && (
                          <FileSelectAndPreview
                            file={
                              formValues[
                                fieldValue === "image"
                                  ? "introImage"
                                  : "introAudio"
                              ].value
                            }
                            onFileChoosen={onFileChoosen}
                            type={fieldValue}
                            formKey={
                              fieldValue === "image"
                                ? "introImage"
                                : "introAudio"
                            }
                            // isOwner={isOwner}
                            isOwner={!universe || isOwner}
                          />
                        )}
                        {fieldValue === "video" && (
                          <div
                            className={classes.column}
                            key={`FormEntry-${i + 1}`}
                          >
                            <div className={classes.row}>
                              <Typography className={classes.inputLabel}>
                                Youtube ID
                              </Typography>
                              <input
                                className={classes.input}
                                label="Youtube ID"
                                name="youtubeId"
                                value={
                                  formValues.introVideo?.url ||
                                  formValues.introVideo?.value?.url ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleChange(e, "introVideo", "url")
                                }
                                maxLength={200}
                                required
                                // disabled={!isOwner}
                                disabled={!!universe && !isOwner}
                              />
                            </div>
                            <Typography>
                              <span style={{ fontWeight: 300 }}>
                                Example: Copy the bold part of your YouTube link
                                (after = to the end ):
                                https://www.youtube.com/watch?v=
                              </span>
                              <strong style={{ fontWeight: 700 }}>
                                2ZyevrQYCcw
                              </strong>
                            </Typography>
                            {(formValues.introVideo?.url ||
                              formValues.introVideo?.value?.url) && (
                              <YoutubeView
                                id={
                                  formValues.introVideo?.url ||
                                  formValues.introVideo?.value?.url
                                }
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                return (
                  <Fragment key={`Field-${i + 1}`}>
                    <label htmlFor={fieldKey}>
                      {fieldValue.formTitle} : {fieldValue.formDescription}
                    </label>
                    {fieldValue.formType === "textarea" && (
                      <textarea
                        className={classes.input}
                        label={fieldKey}
                        name={fieldKey}
                        defaultValue={fieldValue.value}
                        {...fieldValue.inputProps}
                        onChange={(e) => handleChange(e, fieldKey, "value")}
                        required
                        // disabled={!isOwner}
                        disabled={!!universe && !isOwner}
                      />
                    )}
                    {fieldValue.formType === "text" && (
                      <input
                        className={classes.input}
                        type={fieldValue.formType}
                        label={fieldKey}
                        name={fieldKey}
                        defaultValue={fieldValue.value}
                        {...fieldValue.inputProps}
                        onChange={(e) => handleChange(e, fieldKey, "value")}
                        required
                        // disabled={!isOwner}
                        disabled={!!universe && !isOwner}
                      />
                    )}
                    {fieldValue.formType === "file" && (
                      <FileSelectAndPreview
                        file={formValues[fieldKey].value}
                        onFileChoosen={onFileChoosen}
                        type="image"
                        formKey={fieldKey}
                        // isOwner={isOwner}
                        isOwner={!universe || isOwner}
                      />
                    )}

                    {/* {errors[fieldKey] && <span style={{ color: "red" }}>Required</span>} */}
                    <br />
                  </Fragment>
                );
              })}
            </Paper>
            <div style={{ marginTop: 40 }} />
            {!universe && isOwner && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon>Create mission</CloudUploadIcon>}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  padding: 20,
                  marginBottom: 10,
                  fontSize: 16,
                }}
              >
                Create mission
              </Button>
            )}
            {/* {universe && isOwner && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon>Update universe</CloudUploadIcon>}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  padding: 20,
                  marginBottom: 10,
                  fontSize: 16,
                }}
              >
                Update universe
              </Button>
            )} */}
          </form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ userId }) => ({ userId });

export default connect(mapStateToProps)(UniverseForm);
