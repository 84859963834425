/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://w4ptg2h5fzc6xfdeufyelet5ei.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://u09nsty886.execute-api.eu-west-1.amazonaws.com/inp",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:ee84d39f-2ccd-4b23-8f70-e5a9102900ff",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_SgzI4GVv8",
    "aws_user_pools_web_client_id": "4p5bi5uqh7308lg12kvvv1pcnj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sgb-escape-assetsbackend-inp",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "sgb-escape-backend-20221108101334-hostingbucket-inp",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dmd0fteqr1gra.cloudfront.net"
};


export default awsmobile;
