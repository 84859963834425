import React, { Fragment } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: 4,
    boxSizing: "border-box",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-around",
  },
  updateButton: { margin: 10 },
  select: {
    minWidth: "20ch",
    margin: "10px 10px 10px 0",
  },
  title: { marginTop: 30, marginBottom: 10 },
}));

const GameEntries = ({ formEntries, register, isOwner, universes, errors }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Game information
      </Typography>
      <Paper style={{padding: 30}}>
        {formEntries
          .filter(
            ([fieldKey]) => fieldKey !== "inventory" && fieldKey !== "questions"
          )
          .map(([fieldKey, fieldValue], i) => (
            <Fragment key={`Field-${i + 1}`}>
              <label htmlFor={fieldKey}>{fieldValue.formTitle}</label>
              <div className={classes.row}>
                {fieldValue.formType === "textarea" && (
                  <textarea
                    className={classes.input}
                    label={fieldKey}
                    name={fieldKey}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={fieldValue.value}
                    {...fieldValue.inputProps}
                    disabled={!isOwner}
                  />
                )}
                {(fieldValue.formType === "text" ||
                  fieldValue.formType === "number" ||
                  fieldValue.formType === "file") && (
                  <input
                    className={classes.input}
                    type={fieldValue.formType}
                    label={fieldKey}
                    name={fieldKey}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={fieldValue.value}
                    {...fieldValue.inputProps}
                    disabled={!isOwner}
                  />
                )}
                {fieldValue.formType === "select" && (
                  <>
                    <br />
                    <select
                      className={classes.select}
                      name={fieldKey}
                      defaultValue={fieldValue.value}
                      ref={register({
                        required: true,
                      })}
                      disabled={!isOwner}
                    >
                      {universes.map((universe) => (
                        <option key={universe.id} value={universe.id}>
                          {universe.title}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
              {errors[fieldKey] && (
                <span style={{ color: "red" }}>Required</span>
              )}
              <br />
            </Fragment>
          ))}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ universes }) => ({ universes });

export default connect(mapStateToProps)(GameEntries);
