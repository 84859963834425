import React from "react";
import { Typography, Button, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Question from "./Question";

const Questions = ({
  questions,
  addQuestion,
  addAnswer,
  moveItem,
  handleArrayChange,
  handleAnswerChange,
  onFileChoosen,
  deleteAnswer,
  deleteQuestion,
  isOwner,
}) => {
  return (
    <>
      <Typography variant="h4" style={{ marginTop: 30, marginBottom: 10 }}>Questions</Typography>
      <Paper style={{padding: 30}}>
        {questions.map((question, i) => (
          <Question
            key={`Question-${i + 1}`}
            question={question}
            index={i}
            addAnswer={addAnswer}
            questionsLength={questions.length}
            moveItem={moveItem}
            handleAnswerChange={handleAnswerChange}
            handleArrayChange={handleArrayChange}
            onFileChoosen={onFileChoosen}
            deleteAnswer={deleteAnswer}
            deleteQuestion={deleteQuestion}
            isOwner={isOwner}
          />
        ))}
        {isOwner && (
          <Button
            color="primary"
            type="button"
            variant="contained"
            onClick={addQuestion}
            startIcon={<AddIcon>Add question</AddIcon>}
          >
            Add question
          </Button>
        )}
      </Paper>
    </>
  );
};

export default Questions;
