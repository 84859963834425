import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
// import Amplify, { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import Amplify from "aws-amplify";
import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import awsconfig from "./aws-exports";
import AppContainer from "./components/AppContainer";
// import { listGames, getGame } from "./graphql/queries";
// import { createGameFromLambda, deleteGame } from "./graphql/mutations";
Amplify.configure(awsconfig);
// Amplify.configure({
//   ...awsconfig,
//   graphql_headers: () => {},
//   API: {
//     graphql_endpoint: "",
//     graphql_headers: () => {},
//     endpoints: [
//       {
//         name: "notes",
//         endpoint: awsconfig.aws_appsync_graphqlEndpoint,
//         region: "eu-west-1",
//       },
//     ],
//   },
// });
// Storage.configure({
//   bucket: awsconfig.aws_user_files_s3_bucket,
//   region: awsconfig.aws_appsync_region,
//   identityPoolId: awsconfig.aws_cognito_identity_pool_id,
// });

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", "Arial", sans-serif',
    fontSize: 12,
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
  },
  // palette: {
  //   primary: {
  //     main: 'rgb(239,239,244)',
  //   },
  //   secondary: {
  //     main: '#ffffff',
  //   },
  //   tertiary: {
  //     main: dialColor,
  //   },
  // },
});

// const testQuery = async () => {
//   // const queryResult = await API.graphql(graphqlOperation(listGames));
//   // console.log({ queryResult });
//   const queryResult2 = await API.graphql(
//     graphqlOperation(getGame, { id: "6f526bc8-2ee2-45fa-a0d8-ff988e37445c" })
//   );
//   console.log({ queryResult2 });
// };

// const testMutation = async () => {
//   const mutationResult = await API.graphql(
//     graphqlOperation(deleteGame, {
//       input: JSON.stringify({ test: "test" }),
//     })
//   );
//   console.log({ mutationResult });
// };

// const App = () => {
//   testQuery();
//   testMutation();
//   return <div>coucou</div>;
// };

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer />
    </MuiThemeProvider>
  </Provider>
);

export default App;
