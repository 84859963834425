export const emptyGame = () => ({
  title: {
    formTitle: "Title",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 200 },
  },
  author: {
    formTitle: "Author",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 50 },
  },
  institution: {
    formTitle: "Institution",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 50 },
  },
  masterCode: {
    formTitle: "Master Code: enables admin mode when entered during game",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: {
      maxLength: 3,
      onChange: (e) => {
        const { value } = e.target;
        const regex = /[^A-Za-z0-9]+/;
        if (value.match(regex)) return;
        return value.toUpperCase();
      },
    },
  },
  timer: {
    formTitle: "Time limit (in minutes)",
    formDescription: "",
    value: 60,
    hasError: false,
    formType: "number",
    inputProps: { min: 10, max: 999 },
  },
  lives: {
    formTitle: "Starting lives: game is over when it reaches 0",
    formDescription: "",
    value: 6,
    hasError: false,
    formType: "number",
    inputProps: { min: 1, max: 9999 },
  },
  penalityTimer: {
    formTitle:
      "Penality timer (in seconds): game timer will lose this value if a wrong answer is entered during game",
    formDescription: "",
    value: 180,
    hasError: false,
    formType: "number",
    inputProps: { min: 0, max: 9999 },
  },
  penalityCounter: {
    formTitle:
      "Penality counter: penality life will apply every X (penality counter) wrong answers",
    formDescription: "",
    value: 2,
    hasError: false,
    formType: "number",
    inputProps: { min: 0, max: 9999 },
  },
  penalityLife: {
    formTitle: "Penality life: lives lost when penality counter is reached",
    formDescription: "",
    value: 1,
    hasError: false,
    formType: "number",
    inputProps: { min: 0, max: 9999 },
  },
  gameUniverseId: {
    formTitle: "Mission",
    formDescription: "",
    value: "b4d76a9c-6e97-41ec-92e2-4fed9b821fbe",
    hasError: false,
    formType: "select",
  },
});

export const emptyUniverse = () => ({
  author: {
    formTitle: "Author name",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 50 },
  },
  title: {
    formTitle: "Title",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 50 },
  },
  introText: {
    formTitle: "Introduction text",
    formDescription:
      "This will be displayed during mission introduction (660 characters max)",
    value: "",
    hasError: false,
    formType: "textarea",
    inputProps: { maxLength: 660, rows: 5 },
  },
  introImage: {
    formTitle: "Introduction image",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
  introVideo: {
    formTitle: "Introduction video",
    formDescription:
      "Only YouTube is supported, please insert video ID only (example: 2ZyevrQYCcw)",
    value: "",
    hasError: false,
    formType: "text",
    inputProps: { maxLength: 255 },
  },
  introAudio: {
    formTitle: "Introduction audio",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: ".mp3" },
  },
  mainBackground: {
    formTitle: "Main background",
    formDescription: "",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
  timerBackground: {
    formTitle: "Timer background",
    formDescription: "For a good result, pick a square and transparent PNG",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
  rightAnswerBackground: {
    formTitle: "Right answer background",
    formDescription: "For a good result, pick a square and transparent PNG",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
  wrongAnswerBackground: {
    formTitle: "Wrong answer background",
    formDescription: "For a good result, pick a square and transparent PNG",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
  lifeBackground: {
    formTitle: "Life background",
    formDescription: "For a good result, pick a square and transparent PNG",
    value: "",
    hasError: false,
    formType: "file",
    inputProps: { multiple: false, accept: "image/png, image/jpeg" },
  },
});

export const emptyQuestion = () => ({
  image: {
    type: "image",
    title: "",
    s3key: "",
  },
  audio: {
    type: "audio",
    title: "",
    s3key: "",
  },
  video: {
    type: "video",
    title: "",
    url: "",
  },
  assetType: "image",
  type: "question",
  text: "",
  correctAnswer: 0,
  answers: [emptyAnswer()],
  hint: false,
});

const emptyAnswer = () => ({ value: "", description: "" });

export const emptyInventoryItem = () => ({
  type: "image",
  title: "",
  s3key: "",
  url: "",
});

// Game details/update
export const getGameFormInitialValues = (game) => {
  console.log({game})
  const initialValues = {
    inventory: [],
    questions: [],
  };
  if (!game) return { ...initialValues, ...emptyGame() };

  const gameFormEntries = Object.entries({ ...emptyGame() });

  gameFormEntries.forEach(([key, value]) => {
    initialValues[key] = value;
    initialValues[key].value = game[key];
    if (key === "gameUniverseId") initialValues[key].value = game.universe.id;
  });
  for (let i = 0; i < game.inventory.length; i++) {
    initialValues.inventory.push(emptyInventoryItem());
    for (const [key, value] of Object.entries(game.inventory[i])) {
      initialValues.inventory[i][key] = value;
    }
  }
  for (let i = 0; i < game.questions.length; i++) {
    initialValues.questions.push(emptyQuestion());
    for (const [key, value] of Object.entries(game.questions[i])) {
      if (key === "answers") {
        initialValues.questions[i].answers = [];
        game.questions[i].answers.forEach((answer) => {
          initialValues.questions[i].answers.push({ ...answer });
        });
      } else {
        // console.log({ question: initialValues.questions[i], value, key });
        if (value) initialValues.questions[i][key] = value;
      }
    }
    // check assetType
    if (initialValues.questions[i].video.url !== "")
      initialValues.questions[i].assetType = "video";
    else if (initialValues.questions[i].audio.s3key !== "")
      initialValues.questions[i].assetType = "audio";
    else if (initialValues.questions[i].image.s3key !== "")
      initialValues.questions[i].assetType = "image";
    else initialValues.questions[i].assetType = "none";
  }

  return { ...initialValues };
};

export const getUniverseFormInitialValues = (universe) => {
  if (!universe) return { introType: "image", ...emptyUniverse() };

  const initialValues = {};
  const universeFormEntries = Object.entries(emptyUniverse());

  console.log({ universe, universeFormEntries });
  universeFormEntries.forEach(([key, value]) => {
    initialValues[key] = value;
    if (value.formType === "file") {
      if (universe[key] && universe[key].s3key) {
        initialValues[key].value = universe[key].s3key;
      } else {
        delete initialValues[key];
      }
    } else initialValues[key].value = universe[key];
  });

  // // check introType
  console.log({ initialValues });
  if (initialValues.introVideo && initialValues.introVideo.value === null)
    delete initialValues.introVideo;

  if (initialValues.introVideo) initialValues.introType = "video";
  else if (initialValues.introImage) initialValues.introType = "image";
  else if (initialValues.introAudio) initialValues.introType = "audio";
  else initialValues.introType = "text";

  return { ...initialValues };
};
