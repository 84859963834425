import React from "react";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  TextField,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FileSelectAndPreview from "./FileSelectAndPreview";
import YoutubeView from "./YoutubeView";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginLeft: 30,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },
  inputLabel: { width: "10ch" },
  margin: {
    margin: theme.spacing(1),
  },
}));

const InventoryItem = ({
  index,
  item,
  inventoryLength,
  handleArrayChange,
  onFileChoosen,
  deleteInventoryItem,
  moveItem,
  isOwner,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" style={{ textDecoration: "underline" }}>
        Document n°{index + 1}
      </Typography>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Asset type</Typography>
        <Select
          value={item.type}
          onChange={(e) => handleArrayChange(e, "inventory", index, "type")}
        >
          <MenuItem value={"image"}>Image</MenuItem>
          <MenuItem value={"video"}>Video</MenuItem>
          <MenuItem value={"audio"}>Audio</MenuItem>
          {/* <MenuItem value={"text"}>Text</MenuItem> */}
        </Select>
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Title</Typography>
        <TextField
          required
          label="Title"
          value={item.title}
          type="text"
          onChange={(e) => handleArrayChange(e, "inventory", index, "title")}
          error={item.url === null}
          // helperText={hasError && error}
        />
      </div>
      <div className={classes.row}>
        {(item.type === "image" || item.type === "audio") && (
          <FileSelectAndPreview
            file={item.s3key}
            onFileChoosen={onFileChoosen}
            type={item.type}
            index={index}
            formKey={"inventory"}
            isOwner={isOwner}
          />
        )}
        {item.type === "video" && (
          <div className={classes.column}>
            <div className={classes.row}>
              <Typography className={classes.inputLabel}>Youtube ID</Typography>
              <input
                className={classes.input}
                label="Youtube ID"
                name="youtubeId"
                value={item.url}
                onChange={(e) =>
                  handleArrayChange(e, "inventory", index, "url")
                }
                maxLength={200}
                required
              />
            </div>
            <Typography>
              <span style={{ fontWeight: 300 }}>
                Example: Copy the bold part of your YouTube link (after = to the
                end ): https://www.youtube.com/watch?v=2ZyevrQYCcw
              </span>
              <strong style={{ fontWeight: 700 }}>2ZyevrQYCcw</strong>
            </Typography>
            {item.url && <YoutubeView id={item.url} />}
          </div>
        )}
      </div>

      <Button
        onClick={() => deleteInventoryItem(index)}
        variant="contained"
        color="secondary"
        className={classes.margin}
        startIcon={<DeleteIcon>Delete document</DeleteIcon>}
      >
        Delete document
      </Button>
      {index !== 0 && (
        <Button
          onClick={() => moveItem("inventory", index, -1)}
          variant="contained"
          color="primary"
          className={classes.margin}
          startIcon={<ArrowUpwardIcon>Move up</ArrowUpwardIcon>}
        >
          Move up
        </Button>
      )}
      {index < inventoryLength - 1 && (
        <Button
          onClick={() => moveItem("inventory", index, 1)}
          variant="contained"
          color="primary"
          className={classes.margin}
          startIcon={<ArrowDownwardIcon>Move down</ArrowDownwardIcon>}
        >
          Move down
        </Button>
      )}
    </div>
  );
};

export default InventoryItem;
