import React from "react";

const UniverseTimer = ({ backgroundUrl }) => (
  <div
    style={{
      position: "absolute",
      top: "calc(320px - 50%)",
      left: "calc(185px - 50%)",
      width: "300px",
      height: "300px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <span style={{ color: "white", textAlign: "center", fontSize: 44 }}>
      60:00
    </span>
  </div>
);

export default UniverseTimer;
