import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";

const UniverseTopRow = ({ lifeBackground }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: "50px",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <div
      style={{
        color: "black",
        backgroundColor: "white",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SettingsIcon fontSize="large">Settings</SettingsIcon>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
        borderRadius: "50px",
        alignItems: "center",
        padding: "0 5px 0 5px",
      }}
    >
      <div
        style={{
          backgroundImage: lifeBackground ? `url(${lifeBackground})` : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "50px",
          height: "50px",
        }}
      />
      <span style={{ color: "black", fontSize: 30 }}>6/6</span>
    </div>
  </div>
);

export default UniverseTopRow;
