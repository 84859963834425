import { emptyUniverse } from "../constants/dataBaseFormat";

export const userIdReducer = (state = null, action) => {
  if (action.type === "SET_USER_ID") {
    return action.data;
  }
  return state;
};

export const userEmailReducer = (state = null, action) => {
  if (action.type === "SET_USER_EMAIL") {
    return action.data;
  }
  return state;
};

export const universeFormReducer = (state = emptyUniverse(), action) => {
  if (action.type === "SET_UNIVERSE") {
    return action.data;
  }
  if (action.type === "RESET_UNIVERSE") {
    return emptyUniverse();
  }
  return state;
};

export const universesReducer = (state = [], action) => {
  if (action.type === "SET_UNIVERSES") {
    return action.data;
  }
  return state;
};

export const gamesReducer = (state = [], action) => {
  if (action.type === "SET_GAMES") {
    return action.data;
  }
  if (action.type === "UPDATE_GAMES_CACHE") {
    const { id } = action.data;
    const newState = [...state];
    const gameIndex = newState.findIndex((game) => game.id === id);
    if (gameIndex === -1) return state;

    for (const [key, value] of Object.entries(action.data)) {
      if (key === "id") continue;
      newState[gameIndex][key] = value;
    }
    return newState;
  }
  return state;
};
