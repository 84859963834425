import React, { useRef, useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ImageFromS3 from "../components/ImageFromS3";
import ReactToPrint from "react-to-print";
import { Component } from "react";
import { encodeToQRCode } from "../constants/helpers";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginBottom: 30,
  },
  margin: {
    margin: 5,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  inputLabel: { width: "10ch" },
}));

const QRCodes = ({ game, onBack, userEmail }) => {
  const [email, setEmail] = useState(userEmail);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const classes = useStyles();
  const componentRef = useRef(null);

  const handleSubmit = () => {
    // TODO: email validation ?
    setEmailSubmitted(true);
  };
  return (
    <div className={classes.root}>
      <Button
        onClick={onBack}
        type="button"
        variant="outlined"
        color="primary"
        className={classes.margin}
      >
        Back
      </Button>
      {!emailSubmitted ? (
        <div>
          <Typography>
            Please enter your email: game results will be sent to it.
          </Typography>
          <Typography>
            We won't store it anywhere, it will only be stored in the QR Code.
          </Typography>
          <Typography>You can leave it blank.</Typography>
          <div className={classes.row}>
            <Typography className={classes.inputLabel}>Email</Typography>
            <input
              className={classes.input}
              type="text"
              label="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      ) : (
        <>
          <ReactToPrint
            trigger={() => (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.margin}
              >
                Print QR codes
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Divider className={classes.divider} />
          <ComponentToPrint
            ref={(el) => (componentRef.current = el)}
            game={game}
            email={email}
          />
        </>
      )}
    </div>
  );
};

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameB64: null,
    };
  }

  async componentDidMount() {
    const { game, email } = this.props;
    encodeToQRCode(game.id, email)
      .then((gameB64) => this.setState({ gameB64 }))
      .catch(console.log);
  }

  render() {
    const { game } = this.props;
    const { gameB64 } = this.state;
    const styles = {
      text: {
        textAlign: "center",
      },
      imgContainer: {
        display: "flex",
        justifyContent: "center",
        // width: 100,
        // height: 100,
      },
    };
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs={6} alignItems="center">
          <Typography variant="h4" style={styles.text}>
            Game
          </Typography>
          <div style={styles.imgContainer}>
            {gameB64 ? (
              <img
                src={gameB64}
                alt=""
                style={{ width: 300, height: "auto" }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </Grid>

        {game.questions.map((question, i) => (
          <Grid key={`QuestionQR-${i + 1}`} item xs={6} alignItems="center">
            <Typography variant="h4" style={styles.text}>
              Q{i + 1}
            </Typography>
            <div style={styles.imgContainer}>
              <ImageFromS3 s3Key={`qrcodes/Question-${question.index}.png`} />
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = ({ userEmail }) => ({ userEmail });

export default connect(mapStateToProps)(QRCodes);
