import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AppBar, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { signOut } from "../constants/auth";
import logo from "../images/Logo SGB Escape.png";

const CustomTab = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    "&$selected": {
      color: "white",
      background: "rgb(44,84,160)",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const NavBar = ({ handleNavChange, selectedNav, userEmail }) => {
  // const classes = useStyles();

  return (
    // <Paper square>
    <AppBar
      position="sticky"
      color="inherit"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        padding: 10,
      }}
    >
      <img src={logo} style={{ objectFit: "contain", maxHeight: 60 }} />
      <div style={{ display: "flex", flex: 1 }} />
      <Tabs
        defaultValue={selectedNav}
        value={selectedNav}
        onChange={handleNavChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomTab label="Games"/>
        {/* <Tab label="Create game" /> */}
        <CustomTab label="Missions" />
        {/* <Tab label="Create mission" /> */}
      </Tabs>
      <div style={{ display: "flex", flex: 1 }} />
      <Button
        onClick={signOut}
        // variant="outlined"
        color="secondary"
        // className={classes.margin}
        startIcon={<ExitToAppIcon>Disconnect</ExitToAppIcon>}
        // style={{ alignSelf: "center" }}
      >
        {userEmail}
      </Button>
    </AppBar>
    // </Paper>
  );
};

const mapStateToProps = ({ userEmail }) => ({ userEmail });

export default connect(mapStateToProps)(NavBar);
