import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core";
import { Storage } from "aws-amplify";
import SmartPhone from "./SmartPhone";
import UniverseTimer from "./UniverseTimer";
import UniverseTopRow from "./UniverseTopRow";
import UniverseAnswers from "./UniverseAnswers";
import { getUniverseImagesValues } from "./functions";

// const useStyles = makeStyles(() => ({}));

const GamePreview = ({ universe, formValues }) => {
  // const classes = useStyles();
  const [images, setImages] = useState(
    getUniverseImagesValues(universe, formValues)
  );

  useEffect(() => {
    if (universe)
      Object.entries(images).forEach(([key, value]) => {
        if (!images[key].url) getUrl(key, value.s3key);
      });
  }, [universe, images]);

  useEffect(() => {
    if (!universe) setImages(getUniverseImagesValues(null, formValues));
  }, [universe, formValues]);

  const getUrl = (imageKey, s3key) => {
    Storage.get(s3key)
      .then((imgUrl) =>
        setImages((s) => ({
          ...s,
          [imageKey]: { ...s[imageKey], url: imgUrl },
        }))
      )
      .catch((err) => console.log({ err }));
  };

  return (
    <SmartPhone
      backgroundUrl={
        images.mainBackground && images.mainBackground.url
          ? images.mainBackground.url
          : null
      }
    >
      <UniverseTimer
        backgroundUrl={
          images.timerBackground && images.timerBackground.url
            ? images.timerBackground.url
            : null
        }
      />
      <UniverseTopRow
        lifeBackground={
          images.lifeBackground && images.lifeBackground.url
            ? images.lifeBackground.url
            : null
        }
      />
      <UniverseAnswers
        wrongBackground={
          images.wrongAnswerBackground && images.wrongAnswerBackground.url
            ? images.wrongAnswerBackground.url
            : null
        }
        rightBackground={
          images.rightAnswerBackground && images.rightAnswerBackground.url
            ? images.rightAnswerBackground.url
            : null
        }
      />
    </SmartPhone>
  );
};

export default GamePreview;
