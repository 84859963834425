import React, { useEffect, useState } from "react";
import Home from "../pages/Home";
import SignInScreen from "../pages/SignInScreen";
import Loading from "../pages/Loading";
import { connect } from "react-redux";
import { signIn } from "../constants/auth";

const AppContainer = ({ userId }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    signIn()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (userId) return <Home />;
  if (loading) return <Loading />;
  return <SignInScreen />;
};

const mapStateToProps = ({ userId }) => ({
  userId,
});

export default connect(mapStateToProps)(AppContainer);
