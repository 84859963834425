import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Typography } from "@material-ui/core";
// import GameDetails from "./GameDetails";
// import GamePage from "./GamePage";
import { connect } from "react-redux";
import { fetchGames } from "../constants/api";
import GameForm from "./GameForm";
import DetailsIcon from "@material-ui/icons/Details";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    // textAlign: "center",
    marginBottom: 30,
  },
});

const Games = ({ userId, games }) => {
  const classes = useStyles();

  const [content, setContent] = useState("view");
  const [openedGame, setOpenedGame] = useState(null);

  const onDetailsClick = (game) => {
    setOpenedGame(game);
  };

  useEffect(() => {
    fetchGames();
  }, []);

  if (content === "create")
    return <GameForm goBack={() => setContent("view")} />;

  return (
    <div>
      {openedGame ? (
        <GameForm game={openedGame} goBack={() => onDetailsClick(null)} />
      ) : (
        <>
          <Typography variant="h4" className={classes.title}>
            GAMES LIST
          </Typography>
          <Paper
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 25,
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Typography
              style={{
                display: "flex",
                flex: 1,
                marginLeft: 20,
                whiteSpace: "pre-wrap",
              }}
            >
              {`Smartgames builder recommends a default mission (Hostage scenario).\nCreate your enigmas & challenges by clicking on "CREATE NEW GAME"`}
            </Typography>
            <Button
              style={{
                background: "rgb(53,97,168)",
                color: "white",
                padding: 15,
                margin: 15,
              }}
              variant="contained"
              onClick={() => setContent("create")}
            >
              Create new game
            </Button>
          </Paper>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Author</TableCell>
                  <TableCell align="center">Institution</TableCell>
                  <TableCell align="center">Created at</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {games.map((game) => (
                  <TableRow key={game.id}>
                    <TableCell align="center">{game.title}</TableCell>
                    <TableCell align="center">{game.author}</TableCell>
                    <TableCell align="center">{game.institution}</TableCell>
                    <TableCell align="center">
                      {new Date(game.createdAt).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={() => onDetailsClick(game)}
                        startIcon={<DetailsIcon>Details</DetailsIcon>}
                      >
                        Details
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        onClick={() => {
                          deleteGame(game.id);
                          fetchGames();
                        }}
                        variant="contained"
                        color="secondary"
                        className={classes.margin}
                        startIcon={<DeleteIcon>Delete game</DeleteIcon>}
                      >
                        Delete game
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ userId, games }) => ({ userId, games });

export default connect(mapStateToProps)(Games);
