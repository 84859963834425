import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IntroductionPreview from "./IntroductionPreview";
import GamePreview from "./GamePreview";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  overlay: {
    width: "100vw",
    height: "100vh",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2,
  },
  margin: {
    margin: 5,
  },
}));

const UniverseWithGamePreview = ({ game, goBack, universes }) => {
  const classes = useStyles();

  const getUniverse = (id) => universes.find((u) => u.id === id);

  console.log({ game, universes });
  const universe =
    game && game.universe ? game.universe : getUniverse(game.gameUniverseId);

  return (
    <div>
      <Button
        color="primary"
        variant="outlined"
        type="button"
        className={classes.margin}
        onClick={goBack}
        startIcon={<ArrowBackIcon>Back</ArrowBackIcon>}
        style={{backgroundColor: "white"}}
      >
        Back
      </Button>
      {!game && !universe ? null : (
        <>
          <Typography variant="h5">Introduction preview</Typography>
          <IntroductionPreview universe={universe} game={game} />
          <Typography variant="h5">In game preview</Typography>
          <GamePreview universe={universe} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ universes }) => ({ universes });

export default connect(mapStateToProps)(UniverseWithGamePreview);
