import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../images/Grenoble_INP_-_Logo_blanc_RVB.png";
import idoDataLogo from "../images/IDO-BLANC.png";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    color: "white",
  },
  logo: {
    width: "50%",
  },
  idoDataLogo: {
    width: "20%",
  },
  error: {
    color: "white",
    fontSize: "1.3em",
  },
}));

function Loading() {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "lightGray",
        textAlign: "center",
      }}
    >
      <img src={logo} className={classes.logo} alt="Grenoble INP" />
      <img src={idoDataLogo} className={classes.idoDataLogo} alt="Ido-Data" />
      <CircularProgress className={classes.progress} />
    </div>
  );
}

export default Loading;
