import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Typography } from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";

import { connect } from "react-redux";
import { fetchUniverses } from "../constants/api";
import UniverseForm from "./UniverseForm";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: { marginBottom: 30 },
});

const Universes = ({ universes }) => {
  const classes = useStyles();

  const [openedUniverse, setOpenedUniverse] = useState(null);
  const [content, setContent] = useState("view");

  const onDetailsClick = (universe) => {
    setOpenedUniverse(universe);
  };

  useEffect(() => {
    fetchUniverses();
  }, []);

  if (content === "create") {
    return <UniverseForm goBack={() => setContent("view")} />;
  }

  return (
    <div>
      {openedUniverse ? (
        <UniverseForm
          universe={openedUniverse}
          goBack={() => onDetailsClick(null)}
        />
      ) : (
        <>
          <Typography variant="h4" className={classes.title}>
            MISSIONS LIST
          </Typography>
          <Paper
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 25,
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Typography
              style={{
                display: "flex",
                flex: 1,
                marginLeft: 20,
                whiteSpace: "pre-wrap",
              }}
            >
              {`For advanced users, create your own mission template (new graphic designs and interface with audio, video and texts, backgrounds, ...)\nIn a hurry ? Use our default HOSTAGE mission template.`}
              {`\n\nCheck out this video to see it`}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              <Button
                style={{
                  background: "rgb(53,97,168)",
                  color: "white",
                  padding: 15,
                  marginBottom: 10,
                }}
                variant="contained"
                onClick={() => setContent("create")}
              >
                Create new mission template
              </Button>
              <Button
                style={{
                  color: "rgb(53,97,168)",
                  background: "white",
                  textTransform: "none",
                  border: "3px solid rgb(53,97,168)",
                  padding: 15,
                }}
                variant="contained"
                onClick={() => window.open("https://www.youtube.com/watch?v=tnqT8kn9Sfs", '_blank')}
              >
                Hostage Mission Preview
              </Button>
            </div>
          </Paper>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Author</TableCell>
                  <TableCell align="center">Created at</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {universes.map((universe) => (
                  <TableRow key={universe.id}>
                    <TableCell align="center">{universe.title}</TableCell>
                    <TableCell align="center">{universe.author}</TableCell>
                    <TableCell align="center">
                      {new Date(universe.createdAt).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={() => onDetailsClick(universe)}
                        startIcon={<DetailsIcon>Details</DetailsIcon>}
                      >
                        Details
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        onClick={() => {
                          deleteUniverse(universe.id);
                          fetchUniverses();
                        }}
                        variant="contained"
                        color="secondary"
                        className={classes.margin}
                        startIcon={<DeleteIcon>Delete universe</DeleteIcon>}
                      >
                        Delete universe
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ universes }) => ({ universes });

export default connect(mapStateToProps)(Universes);
