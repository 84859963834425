import React from "react";

const YoutubeView = ({ id, style = {} }) => {
  console.log({ youtubeVideo: id });
  return (
    <iframe
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
      style={{ width: 300, height: 200, marginTop: 20, ...style }}
    />
  );
};

export default YoutubeView;
