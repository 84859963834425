import React from "react";
import { Box } from "@material-ui/core";

const TabPanel = ({ children, value, index, p = 3, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    style={{ width: "100%", display: "flex", overflow: "auto" }}
    {...other}
  >
    {value === index && (
      <Box
        p={p}
        background="white"
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    )}
  </div>
);

export default TabPanel;
