import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

const ImageFromS3 = ({ s3Key, imgStyle }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    Storage.get(s3Key)
      .then((data) => setUrl(data))
      .catch((err) => {
        console.log("failed :", s3Key);
        console.log({ err });
        return null;
      });
  }, [s3Key]);

  return (
    <img src={url} alt="" style={{ width: 300, height: "auto", ...imgStyle }} />
  );
};

export default ImageFromS3;
