import { combineReducers, createStore } from "redux";
import {
  userIdReducer,
  userEmailReducer,
  universeFormReducer,
  universesReducer,
  gamesReducer,
} from "./reducers";

const appReducer = combineReducers({
  universeForm: universeFormReducer,
  userId: userIdReducer,
  userEmail: userEmailReducer,
  universes: universesReducer,
  games: gamesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = initialState;
  }

  return appReducer(state, action);
};

const initialState = {};

const store = createStore(rootReducer, initialState);

export default store;
