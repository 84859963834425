import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Button, Divider } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Inventory from '../components/Inventory'
import Questions from '../components/Questions'
import { submitGame, submitUpdateGame } from '../constants/functions'
import {
  getGameFormInitialValues,
  emptyInventoryItem,
  emptyQuestion
} from '../constants/dataBaseFormat'
import {
  swapArrElements,
  getUpdatedValues,
  areGameQuestionsUpdated,
  isInventoryUpdated
} from '../constants/formFunctions'
import QRCodes from './QRCodes'
import FormHeader from '../components/FormHeader'
import GameEntries from '../components/GameEntries'
import UniverseWithGamePreview from '../components/UniversePreview/UniverseWithGamePreview'
import { fetchGames, fetchUniverses } from '../constants/api'

const GameForm = ({ userId, dispatch, game, goBack }) => {
  const [loadingText, setLoadingText] = useState(null)
  const [loadingResult, setLoadingResult] = useState(null)
  const [qrPageOpened, setQrPageOpened] = useState(false)
  const [previewPageOpened, setPreviewPageOpened] = useState(false)
  // const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(getGameFormInitialValues(game))

  const isOwner =
    !game ||
    userId === game.createdBy ||
    userId === '706e89ab-2aa8-4315-b59f-c0e6a3043438'

  const { handleSubmit, register, errors, reset, getValues } = useForm()

  const createGame = async values => {
    setLoadingText('Creating game...')
    for (const key of Object.keys(values)) {
      if (formValues[key] && formValues[key].formType === 'number') {
        values[key] = parseInt(values[key])
      }
    }
    const gameFormValues = parseNumberValues(values)
    console.log({ gameFormValues })
    const uploadSuccess = await submitGame({
      ...gameFormValues,
      inventory: formValues.inventory,
      questions: formValues.questions
    })
    if (uploadSuccess) {
      setLoadingResult('Game successfully created !')
      reset()
      setFormValues(getGameFormInitialValues())
      fetchUniverses()
      fetchGames()
      goBack()
    } else setLoadingResult('An error occurred during game creation.')
    setTimeout(() => {
      setLoadingText(null)
      setLoadingResult(null)
    }, 2000)
  }

  const updateGame = async values => {
    // const updatedValue = gameFormValues[fieldKey];
    const gameFormValues = parseNumberValues(values)
    const updatedGameFields = getUpdatedValues(game, gameFormValues)
    const questionsUpdated = areGameQuestionsUpdated(game, formValues.questions)
    if (questionsUpdated) updatedGameFields.questions = formValues.questions
    const inventoryUpdated = isInventoryUpdated(game, formValues.inventory)
    if (inventoryUpdated) updatedGameFields.inventory = formValues.inventory
    console.log({ questionsUpdated, inventoryUpdated })

    console.log({ gameFormValues, updatedGameFields })
    if (!Object.keys(updatedGameFields).length) {
      setLoadingResult('No changes detected, update cancelled')
      setTimeout(() => {
        setLoadingResult(null)
      }, 2000)
      return
    }
    setLoadingText('Updating game...')
    const uploadSuccess = await submitUpdateGame({
      id: game.id,
      ...updatedGameFields
    })
    if (uploadSuccess) {
      setLoadingResult('Game successfully updated !')
      fetchGames()
      goBack()
    } else setLoadingResult('An error occurred during game update.')
    setTimeout(() => {
      setLoadingText(null)
      setLoadingResult(null)
    }, 1000)
  }

  // react-hook-form returns string for input type="number"...
  // => converts form back to number values
  const parseNumberValues = values => {
    for (const key of Object.keys(values)) {
      if (formValues[key] && formValues[key].formType === 'number') {
        values[key] = Number(values[key])
      }
    }
    return values
  }

  const onSubmit = async values => {
    if (!formValues.questions.length) {
      setLoadingResult('No question entered, at least one is required.')
      setTimeout(() => {
        setLoadingResult(null)
      }, 2000)
      return
    }
    if (!game) {
      createGame(values)
    } else {
      updateGame(values)
    }
  }

  const addInventoryItem = () => {
    setFormValues(s => ({
      ...s,
      inventory: [...s.inventory, emptyInventoryItem()]
    }))
  }

  const deleteInventoryItem = index => {
    setFormValues(s => {
      s.inventory.splice(index, 1)
      return {
        ...s,
        inventory: s.inventory
      }
    })
  }

  const handleArrayChange = (e, key, index, subKey, lastKey) => {
    if (subKey === 'correctAnswer') {
      setFormValues(s => {
        s[key][index].correctAnswer = e
        return { ...s }
      })
      return
    }
    const { value, checked } = e.target
    const formValue = subKey === 'hint' ? checked : value
    console.log({ e, key, index, subKey, lastKey, formValue })
    setFormValues(s => {
      if (lastKey) s[key][index][subKey][lastKey] = formValue
      else s[key][index][subKey] = formValue
      if (key === 'questions' && subKey === 'type') {
        if (formValue === 'clue') s.questions[index].answers = []
        else s.questions[index].answers = [emptyQuestion()]
        s.questions[index].correctAnswer = 0
      }
      return { ...s }
    })
  }

  const handleAnswerChange = (e, questionIndex, answerIndex, key) => {
    console.log({ e, questionIndex, answerIndex, key })
    const { value } = e.target
    let formValue = value
    if (key === 'value') {
      const regex = /[^A-Za-z0-9]+/
      if (formValue.match(regex)) return
      formValue = value.toUpperCase()
    }
    setFormValues(s => {
      s.questions[questionIndex].answers[answerIndex][key] = formValue
      return { ...s }
    })
  }

  const deleteAnswer = (questionIndex, index) => {
    setFormValues(s => {
      s.questions[questionIndex].answers.splice(index, 1)
      return { ...s }
    })
  }

  const onFileChoosen = (file, key, index, subKey, lastKey) => {
    console.log({ file, key, index, subKey, lastKey })
    setFormValues(s => {
      if (lastKey)
        s[key][index][subKey][lastKey] = file === undefined ? '' : file
      else s[key][index][subKey] = file === undefined ? '' : file
      return { ...s }
    })
  }

  const addQuestion = () => {
    setFormValues(s => ({
      ...s,
      questions: [...s.questions, emptyQuestion()]
    }))
  }

  const addAnswer = questionIndex => {
    setFormValues(s => {
      s.questions[questionIndex].answers.push({ value: '', description: '' })
      return { ...s }
    })
  }

  const deleteQuestion = index => {
    setFormValues(s => {
      s.questions.splice(index, 1)
      return { ...s }
    })
  }

  const moveItem = (key, index, destIndex) => {
    setFormValues(s => {
      s[key] = swapArrElements(s[key], index, index + destIndex)
      return { ...s }
    })
  }

  const formEntries = Object.entries(formValues)

  console.log({ game, formValues })

  if (game && qrPageOpened)
    return <QRCodes game={game} onBack={() => setQrPageOpened(false)} />

  return (
    <>
      {previewPageOpened && (
        <UniverseWithGamePreview
          game={getValues()}
          goBack={() => setPreviewPageOpened(false)}
        />
      )}
      <div
        style={{
          display: previewPageOpened ? 'none' : 'block',
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <FormHeader
          loadingText={loadingText}
          loadingResult={loadingResult}
          game={game}
          goBack={goBack}
          setQrPageOpened={setQrPageOpened}
          setPreviewPageOpened={setPreviewPageOpened}
          isOwner={isOwner}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <GameEntries
            formEntries={formEntries}
            register={register}
            isOwner={isOwner}
            errors={errors}
          />
          <Divider />
          <Inventory
            addInventoryItem={addInventoryItem}
            deleteInventoryItem={deleteInventoryItem}
            inventory={formValues.inventory}
            handleArrayChange={handleArrayChange}
            moveItem={moveItem}
            onFileChoosen={onFileChoosen}
            isOwner={isOwner}
          />
          <Divider />
          <Questions
            questions={formValues.questions}
            addQuestion={addQuestion}
            addAnswer={addAnswer}
            handleArrayChange={handleArrayChange}
            handleAnswerChange={handleAnswerChange}
            deleteAnswer={deleteAnswer}
            deleteQuestion={deleteQuestion}
            moveItem={moveItem}
            onFileChoosen={onFileChoosen}
            isOwner={isOwner}
          />

          <div style={{ marginTop: 40 }} />
          {!game && isOwner && (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              startIcon={<CloudUploadIcon>Create game</CloudUploadIcon>}
              style={{
                marginLeft: 50,
                marginRight: 50,
                padding: 20,
                marginBottom: 10,
                fontSize: 16
              }}
            >
              Create game
            </Button>
          )}
          {game && isOwner && (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              startIcon={<CloudUploadIcon>Update game</CloudUploadIcon>}
              style={{
                marginLeft: 50,
                marginRight: 50,
                padding: 20,
                marginBottom: 10,
                fontSize: 16
              }}
            >
              Update game
            </Button>
          )}
        </form>
      </div>
    </>
  )
}

const mapStateToProps = ({ userId }) => ({ userId })

export default connect(mapStateToProps)(GameForm)
