import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import SmartPhone from "./SmartPhone";
import { getUniverseImagesValues } from "./functions";
import IntroductionPreviewMedia from "./IntroductionPreviewMedia";

const IntroductionPreview = ({ universe, formValues, game }) => {
  // const classes = useStyles();
  const [images, setImages] = useState(
    getUniverseImagesValues(universe, formValues)
  );

  useEffect(() => {
    if (universe)
      Object.entries(images).forEach(([key, value]) => {
        if (!images[key].url) getUrl(key, value.s3key);
      });
  }, [universe, images]);

  useEffect(() => {
    if (!universe) setImages(getUniverseImagesValues(null, formValues));
  }, [universe, formValues]);

  const getUrl = (imageKey, s3key) => {
    Storage.get(s3key)
      .then((imgUrl) =>
        setImages((s) => ({
          ...s,
          [imageKey]: { ...s[imageKey], url: imgUrl },
        }))
      )
      .catch((err) => console.log({ err }));
  };

  console.log({ images, universe });

  return (
    <SmartPhone
      backgroundUrl={
        images.mainBackground && images.mainBackground.url
          ? images.mainBackground.url
          : null
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color:
              images.mainBackground && images.mainBackground.url
                ? "white"
                : "black",
            fontSize: 30,
          }}
        >
          {game ? game.title : "Game title"}
        </span>
        <span
          style={{
            color:
              images.mainBackground && images.mainBackground.url
                ? "white"
                : "black",
            fontSize: 15,
          }}
        >
          Author: {game ? game.author : "Game author"}
        </span>
        <IntroductionPreviewMedia formValues={universe} />
        {/* {universe?.introVideo?.url ? (
          <div
            style={{
              width: "100%",
              height: "150px",
              marginLeft: "10px",
              marginRight: "10px",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <YoutubeView
              id={
                formValues.introVideo?.url || formValues.introVideo?.value?.url
              }
              style={{
                width: "auto",
                height: "100%",
                marginTop: 0,
                marginLeft: 10
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "150px",
              marginLeft: "10px",
              marginRight: "10px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundImage:
                images.introImage && images.introImage.url
                  ? `url(${images.introImage.url})`
                  : "",
            }}
          />
        )} */}
        <div
          style={{
            backgroundColor: "white",
            width: "calc(100% - 20px)",
            height: "150px",
            margin: "10px",
          }}
        >
          {universe ? universe.introText : formValues.introText.value}
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            width: "calc(100% - 20px)",
            textAlign: "center",
            fontSize: "20px",
            padding: "5px",
            marginTop: "30px",
          }}
        >
          Accept mission
        </div>
      </div>
    </SmartPhone>
  );
};

export default IntroductionPreview;
