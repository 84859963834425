/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      gameUniverseId
      createdAt
      createdBy
      title
      author
      institution
      description
      masterCode
      timer
      lives
      introAudioUrl
      introVideoUrl
      introText
      penalityTimer
      penalityCounter
      penalityLife
      inventory {
        type
        title
        url
        s3key
      }
      questions {
        index
        type
        question
        answers {
          description
          value
        }
        hint
        qrCode
        text
        correctAnswer
        image {
          type
          title
          url
          s3key
        }
        video {
          type
          title
          url
          s3key
        }
        audio {
          type
          title
          url
          s3key
        }
        assetType
      }
      universe {
        id
        author
        createdAt
        createdBy
        title
        introImage {
          type
          title
          url
          s3key
        }
        introAudio {
          type
          title
          url
          s3key
        }
        introVideo {
          type
          title
          url
          s3key
        }
        introText
        mainBackground {
          type
          title
          url
          s3key
          color
        }
        timerBackground {
          type
          title
          url
          s3key
          color
        }
        rightAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        wrongAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        lifeBackground {
          type
          title
          url
          s3key
          color
        }
        updatedAt
      }
      updatedAt
      qrCode
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameUniverseId
        createdAt
        createdBy
        title
        author
        institution
        description
        masterCode
        timer
        lives
        introAudioUrl
        introVideoUrl
        introText
        penalityTimer
        penalityCounter
        penalityLife
        inventory {
          type
          title
          url
          s3key
        }
        questions {
          index
          type
          question
          hint
          qrCode
          text
          correctAnswer
          assetType
        }
        universe {
          id
          author
          createdAt
          createdBy
          title
          introText
          updatedAt
        }
        updatedAt
        qrCode
      }
      nextToken
    }
  }
`;
export const getUniverse = /* GraphQL */ `
  query GetUniverse($id: ID!) {
    getUniverse(id: $id) {
      id
      author
      createdAt
      createdBy
      title
      introImage {
        type
        title
        url
        s3key
      }
      introAudio {
        type
        title
        url
        s3key
      }
      introVideo {
        type
        title
        url
        s3key
      }
      introText
      mainBackground {
        type
        title
        url
        s3key
        color
      }
      timerBackground {
        type
        title
        url
        s3key
        color
      }
      rightAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      wrongAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      lifeBackground {
        type
        title
        url
        s3key
        color
      }
      updatedAt
    }
  }
`;
export const listUniverses = /* GraphQL */ `
  query ListUniverses(
    $filter: ModelUniverseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUniverses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        createdAt
        createdBy
        title
        introImage {
          type
          title
          url
          s3key
        }
        introAudio {
          type
          title
          url
          s3key
        }
        introVideo {
          type
          title
          url
          s3key
        }
        introText
        mainBackground {
          type
          title
          url
          s3key
          color
        }
        timerBackground {
          type
          title
          url
          s3key
          color
        }
        rightAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        wrongAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        lifeBackground {
          type
          title
          url
          s3key
          color
        }
        updatedAt
      }
      nextToken
    }
  }
`;
