import React from "react";

const UniverseAnswers = ({ wrongBackground, rightBackground }) => (
  <div
    style={{
      position: "absolute",
      bottom: "50px",
      left: 0,
      right: 0,
      // width: "300px",
      height: "100px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    }}
  >
    <div
      style={{
        width: "100px",
        height: "100px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: rightBackground ? `url(${rightBackground})` : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "white", fontSize: 20 }}> A</span>
    </div>
    <div
      style={{
        width: "100px",
        height: "100px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: wrongBackground ? `url(${wrongBackground})` : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "white", fontSize: 20 }}>-</span>
    </div>
  </div>
);

export default UniverseAnswers;
