import { Auth } from "aws-amplify";
import store from "../redux/store";

export const signIn = (username, password) =>
  new Promise(async (resolve, reject) => {
    try {
      let user;
      if (username && password) user = await Auth.signIn(username, password);
      else user = await Auth.currentAuthenticatedUser();
      if (user) {
        store.dispatch({
          type: "SET_USER_ID",
          data: user.attributes.sub,
        });
        store.dispatch({
          type: "SET_USER_EMAIL",
          data: user.attributes.email,
        });
      }
      resolve(user);
    } catch (error) {
      console.log("error during sign in:", error);
      reject(error);
    }
  });

export const signOut = async () => {
  await Auth.signOut();
  store.dispatch({ type: "USER_LOGOUT" });
};

export const signUp = async (username, password) =>
  new Promise(async (resolve, reject) => {
    try {
      const user = await Auth.signUp({
        username,
        password,
        // attributes: {
        //   email, // optional
        //   phone_number, // optional - E.164 number convention
        //   // other custom attributes
        // },
      });
      console.log({ user });
      resolve(user);
    } catch (error) {
      console.log("error signing up:", error);
      reject(error);
    }
  });

export const confirmSignUp = (username, code) =>
  new Promise(async (resolve, reject) => {
    try {
      await Auth.confirmSignUp(username, code);
      resolve(true);
    } catch (error) {
      console.log("error confirming sign up", error);
      reject(error);
    }
  });

export const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
    console.log("code resent successfully");
  } catch (err) {
    console.log("error resending code: ", err);
  }
};

export const forgotPassword = (username) =>
  new Promise((resolve, reject) => {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

// Collect confirmation code and new password, then
export const forgotPasswordSubmit = (username, code, new_password) =>
  new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
