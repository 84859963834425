import { createGameFromLambda } from "../graphql/mutations";
import { graphqlOperation, API } from "aws-amplify";
import { fetchGames } from "./api";
// import { checkErrors } from "./formValidation";

// const isQuestionValid = (obj) => {
//   // description: {
//   //   type: "image",
//   //   title: "",
//   //   url: "",
//   // },
//   // type: "question",
//   // question: "",
//   // correctAnswer: 0,
//   // answers: [""],
//   // hint: false,
//   try {
//     const { question, correctAnswer, answers, type, description } = obj;
//     if (question.length === 0) return false;
//     for (const answer of answers) {
//       if (answer.length === 0) return false;
//     }
//     if (description.url === "") return false;
//     return true;
//   } catch (error) {
//     return false;
//   }
// };

// const isItemValid = (obj) => {
//   const { type, title, url } = obj;
//   if (title === "") return false;
//   if (url === "") return false;
//   return true;
// };

// export const isFormValid = () => {
//   try {
//     const currentState = store.getState();
//     const { form, inventory, questions } = currentState;
//     for (const [formKey, formValue] of Object.entries(form)) {
//       if (checkErrors(formKey, formValue.value) !== "") return false;
//     }
//     for (const item of inventory) {
//       if (!isItemValid(item)) return false;
//     }
//     for (const question of questions) {
//       if (!isQuestionValid(question)) return false;
//     }
//     return true;
//   } catch (error) {
//     console.log({ error });
//     return false;
//   }
// };

// const fileToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsBinaryString(file);

//     reader.onload = () => {
//       resolve(btoa(reader.result));
//     };
//     reader.onerror = (err) => {
//       console.log("error during encoding: ", { err });
//       reject(null);
//     };
//   });

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const submitUpdateGame = async (game) => {
  try {
    const updatedGame = {
      ...game,
    };
    if (updatedGame.inventory)
      updatedGame.inventory = await formatInventory(updatedGame.inventory);
    if (updatedGame.questions)
      updatedGame.questions = await formatQuestions(
        updatedGame.questions,
        true
      );

    console.log({ sentGame: game });
    const res = await API.graphql(
      graphqlOperation(createGameFromLambda, {
        data: JSON.stringify(updatedGame),
      })
    );
    console.log({ res });
    const statusCode = parseInt(
      res.data.createGameFromLambda.split("statusCode=")[1].slice(0, 3)
    );
    const success = statusCode === 200;

    return success;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const submitGame = async (formValues) => {
  console.log({ formValues });
  try {
    const game = {
      createType: "game",
      ...formValues,
      inventory: await formatInventory(formValues.inventory),
      questions: await formatQuestions(formValues.questions),
    };
    console.log({ sentGame: game });
    const res = await API.graphql(
      graphqlOperation(createGameFromLambda, {
        data: JSON.stringify(game),
      })
    );
    console.log({ res });
    const statusCode = parseInt(
      res.data.createGameFromLambda.split("statusCode=")[1].slice(0, 3)
    );
    const success = statusCode === 200;

    return success;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const submitUniverse = async (universe) => {
  try {
    console.log("submitUniverse");
    const newUniverse = { ...universe };
    if (newUniverse.introType === "image") {
      newUniverse.introVideo = null;
      newUniverse.introAudio = null;
    }
    if (newUniverse.introType === "video") {
      newUniverse.introImage = null;
      newUniverse.introAudio = null;
    }
    if (newUniverse.introType === "audio") {
      newUniverse.introImage = null;
      newUniverse.introVideo = null;
    }
    delete newUniverse.introType;
    for (const key of Object.keys(newUniverse)) {
      if (newUniverse[key] && newUniverse[key].formType === "file") {
        console.log(newUniverse[key]);
        if (newUniverse[key].value instanceof File) {
          newUniverse[key] = await toBase64(newUniverse[key].value);
          console.log(newUniverse[key]);
        }
      } else if (newUniverse[key] && key === "introVideo") {
        newUniverse.introVideo = { url: newUniverse.introVideo.url || newUniverse.introVideo.value.url };
      } else if (newUniverse[key] && key !== "id") {
        newUniverse[key] = newUniverse[key].value;
      }
    }
    console.log({ newUniverse });

    const res = await API.graphql(
      graphqlOperation(createGameFromLambda, {
        data: JSON.stringify({ ...newUniverse, createType: "universe" }),
      })
    );
    console.log({ res });
    const statusCode = parseInt(
      res.data.createGameFromLambda.split("statusCode=")[1].slice(0, 3)
    );
    console.log({ statusCode });
    const success = statusCode === 200;

    return success;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

// export const submitUniverse = async (formValues) => {
//   try {
//     console.log("submitUniverse");
//     const currentState = store.getState();
//     const { universeForm } = currentState;
//     console.log({ universeForm });
//     const newUniverse = {
//       createType: "universe",
//     };
//     for (const key of Object.keys(formValues)) {
//       if (universeForm[key].formType === "file") {
//         console.log(formValues[key]);
//         newUniverse[key] = await toBase64(formValues[key][0]);
//         console.log(newUniverse[key]);
//       } else {
//         newUniverse[key] = formValues[key];
//       }
//     }
//     console.log({ newUniverse });

//     const res = await API.graphql(
//       graphqlOperation(createGameFromLambda, {
//         data: JSON.stringify(newUniverse),
//       })
//     );
//     console.log({ res });
//     const statusCode = parseInt(
//       res.data.createGameFromLambda.split("statusCode=")[1].slice(0, 3)
//     );
//     console.log({ statusCode });
//     const success = statusCode === 200;
//     if (success) store.dispatch({ type: "RESET_GAME" });

//     return success;
//   } catch (error) {
//     console.log({ error });
//     return false;
//   }
// };

// const formatForm = (form) => {
//   const formattedForm = {};
//   for (const [formKey, formValue] of Object.entries(form)) {
//     formattedForm[formKey] = formValue.value;
//   }
//   return formattedForm;
// };

const formatQuestions = async (questions, update) => {
  const newQuestions = [];
  for (let i = 0; i < questions.length; i++) {
    newQuestions.push({ ...questions[i] });
    const { assetType, video, image, audio } = newQuestions[i];

    newQuestions[i].index = i;

    if ((image && image.s3key === "") || assetType !== "image")
      delete newQuestions[i].image;
    if ((audio && audio.s3key === "") || assetType !== "audio")
      delete newQuestions[i].audio;
    if ((video && video.url === "") || assetType !== "video")
      delete newQuestions[i].video;

    console.log({ assetType, i, newQuestions, video, image, audio });

    if (
      (assetType === "image" || assetType === "audio") &&
      typeof newQuestions[i][assetType].s3key !== "string"
    ) {
      newQuestions[i][assetType].s3key = await toBase64(
        newQuestions[i][assetType].s3key
      );
    }

    if (!update) delete newQuestions[i].assetType;
  }
  return newQuestions;
};

const formatInventory = async (inventory) => {
  console.log({ inventory });
  const newInventory = [];
  for (let i = 0; i < inventory.length; i++) {
    newInventory.push({ ...inventory[i] });
    const { type, s3key } = newInventory[i];
    if ((type === "image" || type === "audio") && typeof s3key !== "string") {
      newInventory[i].s3key = await toBase64(s3key);
    }
  }
  return newInventory;
};
