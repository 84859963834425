import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import FileSelectAndPreview from "./FileSelectAndPreview";
import YoutubeView from "./YoutubeView";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginLeft: 30,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  inputLabel: { width: "15ch" },
  input: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: 4,
    boxSizing: "border-box",
  },
}));

const Question = ({
  index,
  question,
  questionsLength,
  moveItem,
  handleArrayChange,
  handleAnswerChange,
  addAnswer,
  deleteAnswer,
  deleteQuestion,
  onFileChoosen,
  isOwner,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" style={{ textDecoration: "underline" }}>
        QR Code n°{index + 1}
      </Typography>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Type</Typography>
        <Select
          value={question.type}
          onChange={(e) => handleArrayChange(e, "questions", index, "type")}
          disabled={!isOwner}
        >
          <MenuItem value={"question"}>Question</MenuItem>
          <MenuItem value={"qcm"}>QCM</MenuItem>
          <MenuItem value={"clue"}>Clue</MenuItem>
        </Select>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Asset type</Typography>
        <Select
          value={question.assetType}
          onChange={(e) =>
            handleArrayChange(e, "questions", index, "assetType")
          }
          disabled={!isOwner}
        >
          <MenuItem value={"none"}>None</MenuItem>
          <MenuItem value={"image"}>Image</MenuItem>
          <MenuItem value={"audio"}>Audio</MenuItem>
          <MenuItem value={"video"}>Video</MenuItem>
        </Select>
      </div>

      <div className={classes.row}>
        {(question.assetType === "image" || question.assetType === "audio") && (
          <FileSelectAndPreview
            file={question[question.assetType].s3key}
            onFileChoosen={onFileChoosen}
            type={question.assetType}
            index={index}
            formKey={"questions"}
            isOwner={isOwner}
          />
        )}
        {question.assetType === "video" && (
          <div className={classes.column}>
            <div className={classes.row}>
              <Typography className={classes.inputLabel}>Youtube ID</Typography>
              <input
                className={classes.input}
                label="Youtube ID"
                name="youtubeId"
                value={question.video.url}
                onChange={(e) =>
                  handleArrayChange(e, "questions", index, "video", "url")
                }
                maxLength={200}
                required
              />
            </div>
            <Typography>
              <span style={{ fontWeight: 300 }}>
                Example: Copy the bold part of your YouTube link (after = to the
                end ): https://www.youtube.com/watch?v=2ZyevrQYCcw
              </span>
              <strong style={{ fontWeight: 700 }}>2ZyevrQYCcw</strong>
            </Typography>
            {question.video.url && <YoutubeView id={question.video.url} />}
          </div>
        )}
      </div>

      <label htmlFor="text">Text</label>
      <textarea
        className={classes.input}
        label={question.type === "clue" ? "Clue " : "Question"}
        name="text"
        value={question.text}
        onChange={(e) => handleArrayChange(e, "questions", index, "text")}
        maxLength={255}
        required
        disabled={!isOwner}
      />

      {question.type !== "clue" && (
        <label htmlFor="answers">
          Answer{question.answers.length > 1 ? "s" : ""}
        </label>
      )}
      {question.type !== "clue" &&
        question.answers.map((answer, i) => (
          <div className={classes.row} key={`Answer-${i + 1}`}>
            <label htmlFor="answer">Code</label>
            <input
              className={classes.input}
              label="Answer"
              name="answer"
              value={answer.value}
              onChange={(e) => handleAnswerChange(e, index, i, "value")}
              maxLength={1}
              required
              disabled={!isOwner}
            />
            {question.type === "qcm" && (
              <>
                <label htmlFor="description">Answer</label>
                <input
                  className={classes.input}
                  label="Description"
                  name="description"
                  value={answer.description}
                  onChange={(e) =>
                    handleAnswerChange(e, index, i, "description")
                  }
                  maxLength={50}
                  required
                  disabled={!isOwner}
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={question.correctAnswer === i}
                  onChange={(e) =>
                    handleArrayChange(i, "questions", index, "correctAnswer")
                  }
                  color="primary"
                  disabled={!isOwner}
                />
              }
              label="Correct answer"
            />
            {isOwner && question.type === "qcm" && question.answers.length > 1 && (
              <IconButton
                color="secondary"
                aria-label="delete answer"
                className={classes.margin}
                onClick={() => deleteAnswer(index, i)}
              >
                <DeleteIcon fontSize="large" />
              </IconButton>
            )}
          </div>
        ))}
      {isOwner && question.type === "qcm" && question.answers.length < 4 && (
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={() => addAnswer(index)}
          startIcon={<AddIcon>Add answer</AddIcon>}
        >
          Add answer
        </Button>
      )}
      <br />

      <FormControlLabel
        control={
          <Checkbox
            checked={question.hint}
            onChange={(e) => handleArrayChange(e, "questions", index, "hint")}
            color="primary"
            disabled={!isOwner}
          />
        }
        label="Has hint: indicates the players if their inventory item(s) can help them resolve the question"
      />
      {isOwner && (
        <div className={classes.row}>
          <Button
            onClick={() => deleteQuestion(index)}
            variant="contained"
            color="secondary"
            className={classes.margin}
            startIcon={<DeleteIcon>Delete question</DeleteIcon>}
          >
            Delete question
          </Button>
          {index !== 0 && (
            <Button
              onClick={() => moveItem("questions", index, -1)}
              variant="contained"
              color="primary"
              className={classes.margin}
              startIcon={<ArrowUpwardIcon>Move up</ArrowUpwardIcon>}
            >
              Move up
            </Button>
          )}
          {index < questionsLength - 1 && (
            <Button
              onClick={() => moveItem("questions", index, 1)}
              variant="contained"
              color="primary"
              className={classes.margin}
              startIcon={<ArrowDownwardIcon>Move down</ArrowDownwardIcon>}
            >
              Move down
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ questions }) => ({ questions });

export default connect(mapStateToProps)(Question);
