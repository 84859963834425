import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Storage } from "aws-amplify";

const useStyles = makeStyles(() => ({
  overlay: {
    width: "100vw",
    height: "100vh",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2,
  },
  input: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: 4,
    boxSizing: "border-box",
  },
  fileWarning: {
    fontWeight: 300,
    fontSize: 12,
    marginBottom: 10,
  },
}));

const FileSelectAndPreview = ({
  onFileChoosen,
  file,
  type,
  index,
  formKey,
  isOwner,
}) => {
  const classes = useStyles();
  const [url, setUrl] = useState(null);

  const currentType = useRef(type);

  useEffect(() => {
    try {
      if (currentType.current !== type) {
        currentType.current = type;
        setUrl(null);
      }
      if (file) {
        if (typeof file === "string" && file.startsWith("data:")) setUrl(file);
        else if (typeof file === "string" && !file.startsWith("data:")) {
          Storage.get(file)
            .then((data) => setUrl(data))
            .catch((err) => console.log({ err }));
        } else setUrl(URL.createObjectURL(file));
      }
    } catch (error) {
      setUrl(null);
    }
  }, [file, type]);

  const onChange = (e) => {
    try {
      const fileSize = e.target.files[0].size / 1024 / 1024;
      if (type === "image" && fileSize > 1) return;
      if (type === "audio" && fileSize > 3) return;
    } catch (error) {
      return;
    }
    // for universe
    if (typeof index !== "number") {
      onFileChoosen(e.target.files[0], formKey, "value");
    } else {
      // for game
      onFileChoosen(
        e.target.files[0],
        formKey,
        index,
        formKey === "inventory" ? "s3key" : type,
        formKey !== "inventory" ? "s3key" : null
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isOwner && (
        <>
          <input
            className={classes.input}
            type="file"
            multiple={false}
            accept={type === "image" ? "image/png, image/jpeg" : ".mp3"}
            onChange={onChange}
            required={!url}
          />
          {type === "image" ? (
            <Typography className={classes.fileWarning}>
              Accepted formats: PNG and JPEG. 1 Mo maximum. Try to make it as
              small as possible.
            </Typography>
          ) : (
            <Typography className={classes.fileWarning}>
              Accept format: MP3 only. 3 Mo maximum.
            </Typography>
          )}
        </>
      )}
      {url && type === "image" && (
        <img src={url} alt="" style={{ width: 300, height: "auto" }} />
      )}
      {url && type === "audio" && (
        <audio controls="controls">
          <source src={url} type="audio/mp3" />
          Your browser is not compatible
        </audio>
      )}
    </div>
  );
};

export default FileSelectAndPreview;
