import React from "react";
import YoutubeView from "../YoutubeView";

export default function IntroductionPreviewMedia({ formValues }) {
  const videoURL =
    formValues?.introVideo?.url || formValues.introVideo?.value?.url;
  return (
    <div
      style={{
        width: "100%",
        height: "150px",
        marginLeft: "10px",
        marginRight: "10px",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {videoURL ? (
        <YoutubeView
          id={videoURL}
          style={{
            width: "auto",
            height: "100%",
            marginTop: 0,
            marginLeft: 10,
          }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "150px",
            marginLeft: "10px",
            marginRight: "10px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundImage:
              formValues.introImage && formValues.introImage.url
                ? `url(${formValues.introImage.url})`
                : "",
          }}
        />
      )}
    </div>
  );
}
