/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGameFromLambda = /* GraphQL */ `
  mutation CreateGameFromLambda($data: String) {
    createGameFromLambda(data: $data)
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      gameUniverseId
      createdAt
      createdBy
      title
      author
      institution
      description
      masterCode
      timer
      lives
      introAudioUrl
      introVideoUrl
      introText
      penalityTimer
      penalityCounter
      penalityLife
      inventory {
        type
        title
        url
        s3key
      }
      questions {
        index
        type
        question
        answers {
          description
          value
        }
        hint
        qrCode
        text
        correctAnswer
        image {
          type
          title
          url
          s3key
        }
        video {
          type
          title
          url
          s3key
        }
        audio {
          type
          title
          url
          s3key
        }
        assetType
      }
      universe {
        id
        author
        createdAt
        createdBy
        title
        introImage {
          type
          title
          url
          s3key
        }
        introAudio {
          type
          title
          url
          s3key
        }
        introVideo {
          type
          title
          url
          s3key
        }
        introText
        mainBackground {
          type
          title
          url
          s3key
          color
        }
        timerBackground {
          type
          title
          url
          s3key
          color
        }
        rightAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        wrongAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        lifeBackground {
          type
          title
          url
          s3key
          color
        }
        updatedAt
      }
      updatedAt
      qrCode
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      gameUniverseId
      createdAt
      createdBy
      title
      author
      institution
      description
      masterCode
      timer
      lives
      introAudioUrl
      introVideoUrl
      introText
      penalityTimer
      penalityCounter
      penalityLife
      inventory {
        type
        title
        url
        s3key
      }
      questions {
        index
        type
        question
        answers {
          description
          value
        }
        hint
        qrCode
        text
        correctAnswer
        image {
          type
          title
          url
          s3key
        }
        video {
          type
          title
          url
          s3key
        }
        audio {
          type
          title
          url
          s3key
        }
        assetType
      }
      universe {
        id
        author
        createdAt
        createdBy
        title
        introImage {
          type
          title
          url
          s3key
        }
        introAudio {
          type
          title
          url
          s3key
        }
        introVideo {
          type
          title
          url
          s3key
        }
        introText
        mainBackground {
          type
          title
          url
          s3key
          color
        }
        timerBackground {
          type
          title
          url
          s3key
          color
        }
        rightAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        wrongAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        lifeBackground {
          type
          title
          url
          s3key
          color
        }
        updatedAt
      }
      updatedAt
      qrCode
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      gameUniverseId
      createdAt
      createdBy
      title
      author
      institution
      description
      masterCode
      timer
      lives
      introAudioUrl
      introVideoUrl
      introText
      penalityTimer
      penalityCounter
      penalityLife
      inventory {
        type
        title
        url
        s3key
      }
      questions {
        index
        type
        question
        answers {
          description
          value
        }
        hint
        qrCode
        text
        correctAnswer
        image {
          type
          title
          url
          s3key
        }
        video {
          type
          title
          url
          s3key
        }
        audio {
          type
          title
          url
          s3key
        }
        assetType
      }
      universe {
        id
        author
        createdAt
        createdBy
        title
        introImage {
          type
          title
          url
          s3key
        }
        introAudio {
          type
          title
          url
          s3key
        }
        introVideo {
          type
          title
          url
          s3key
        }
        introText
        mainBackground {
          type
          title
          url
          s3key
          color
        }
        timerBackground {
          type
          title
          url
          s3key
          color
        }
        rightAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        wrongAnswerBackground {
          type
          title
          url
          s3key
          color
        }
        lifeBackground {
          type
          title
          url
          s3key
          color
        }
        updatedAt
      }
      updatedAt
      qrCode
    }
  }
`;
export const createUniverse = /* GraphQL */ `
  mutation CreateUniverse(
    $input: CreateUniverseInput!
    $condition: ModelUniverseConditionInput
  ) {
    createUniverse(input: $input, condition: $condition) {
      id
      author
      createdAt
      createdBy
      title
      introImage {
        type
        title
        url
        s3key
      }
      introAudio {
        type
        title
        url
        s3key
      }
      introVideo {
        type
        title
        url
        s3key
      }
      introText
      mainBackground {
        type
        title
        url
        s3key
        color
      }
      timerBackground {
        type
        title
        url
        s3key
        color
      }
      rightAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      wrongAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      lifeBackground {
        type
        title
        url
        s3key
        color
      }
      updatedAt
    }
  }
`;
export const updateUniverse = /* GraphQL */ `
  mutation UpdateUniverse(
    $input: UpdateUniverseInput!
    $condition: ModelUniverseConditionInput
  ) {
    updateUniverse(input: $input, condition: $condition) {
      id
      author
      createdAt
      createdBy
      title
      introImage {
        type
        title
        url
        s3key
      }
      introAudio {
        type
        title
        url
        s3key
      }
      introVideo {
        type
        title
        url
        s3key
      }
      introText
      mainBackground {
        type
        title
        url
        s3key
        color
      }
      timerBackground {
        type
        title
        url
        s3key
        color
      }
      rightAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      wrongAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      lifeBackground {
        type
        title
        url
        s3key
        color
      }
      updatedAt
    }
  }
`;
export const deleteUniverse = /* GraphQL */ `
  mutation DeleteUniverse(
    $input: DeleteUniverseInput!
    $condition: ModelUniverseConditionInput
  ) {
    deleteUniverse(input: $input, condition: $condition) {
      id
      author
      createdAt
      createdBy
      title
      introImage {
        type
        title
        url
        s3key
      }
      introAudio {
        type
        title
        url
        s3key
      }
      introVideo {
        type
        title
        url
        s3key
      }
      introText
      mainBackground {
        type
        title
        url
        s3key
        color
      }
      timerBackground {
        type
        title
        url
        s3key
        color
      }
      rightAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      wrongAnswerBackground {
        type
        title
        url
        s3key
        color
      }
      lifeBackground {
        type
        title
        url
        s3key
        color
      }
      updatedAt
    }
  }
`;
