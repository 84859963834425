import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Typography, Button, Paper } from "@material-ui/core";
import InventoryItem from "./InventoryItem";

const Inventory = ({
  inventory,
  addInventoryItem,
  deleteInventoryItem,
  handleArrayChange,
  moveItem,
  onFileChoosen,
  isOwner,
}) => {
  return (
    <>
      <Typography variant="h4" style={{ marginTop: 30, marginBottom: 10 }}>Inventory</Typography>
      <Paper style={{padding: 30}}>
        {inventory.map((item, i) => (
          <InventoryItem
            key={`Inventory-${i + 1}`}
            item={item}
            index={i}
            deleteInventoryItem={deleteInventoryItem}
            handleArrayChange={handleArrayChange}
            moveItem={moveItem}
            onFileChoosen={onFileChoosen}
            inventoryLength={inventory.length}
            isOwner={isOwner}
          />
        ))}
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={addInventoryItem}
          startIcon={<AddIcon>Add inventory item</AddIcon>}
        >
          Add inventory item
        </Button>
      </Paper>
    </>
  );
};

export default Inventory;
