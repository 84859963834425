import React from "react";
import { deleteGame } from "../constants/api";
import {
  Button,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(() => ({
  overlay: {
    width: "100vw",
    height: "100vh",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2,
  },
  margin: {
    margin: 5,
  },
}));

const FormHeader = ({
  loadingText,
  loadingResult,
  game,
  goBack,
  setQrPageOpened,
  setPreviewPageOpened,
  isOwner,
}) => {
  const classes = useStyles();
  return (
    <>
      {loadingText && !loadingResult && (
        <div className={classes.overlay}>
          <Typography variant="h4" style={{ color: "white" }}>
            {loadingText}
          </Typography>
          <CircularProgress />
        </div>
      )}
      {loadingResult && (
        <div className={classes.overlay}>
          <Typography variant="h4" style={{ color: "white" }}>
            {loadingResult}
          </Typography>
        </div>
      )}

      <>
        <Button
          color="primary"
          variant="outlined"
          type="button"
          className={classes.margin}
          onClick={goBack}
          startIcon={<ArrowBackIcon>Back</ArrowBackIcon>}
          style={{background: "white" }}
        >
          Back
        </Button>
        {game && (
          <Button
            color="primary"
            variant="contained"
            type="button"
            className={classes.margin}
            onClick={() => setQrPageOpened(true)}
            startIcon={<CropFreeIcon> QR codes</CropFreeIcon>}
          >
            QR codes
          </Button>
        )}
      </>

      <Button
        color="primary"
        variant="contained"
        type="button"
        className={classes.margin}
        onClick={() => setPreviewPageOpened(true)}
        startIcon={<VisibilityIcon>Preview game with mission</VisibilityIcon>}
      >
        Preview game with mission
      </Button>
      {game && isOwner && (
        <Button
          onClick={() => {
            deleteGame(game.id);
            goBack();
          }}
          variant="contained"
          color="secondary"
          className={classes.margin}
          startIcon={<DeleteIcon>Delete game</DeleteIcon>}
        >
          Delete game
        </Button>
      )}
    </>
  );
};

export default FormHeader;
