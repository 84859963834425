export const getUniverseImagesValues = (universe, formValues) => {
  const images = {};
  if (universe)
    for (const key of Object.keys(universe)) {
      if (universe[key] && universe[key].s3key)
        images[key] = { s3key: universe[key].s3key, url: null };
    }
  else
    for (const key of Object.keys(formValues)) {
      if (
        formValues[key] &&
        formValues[key].formType === "file" &&
        formValues[key].value !== ""
      )
        images[key] = {
          s3key: null,
          url: URL.createObjectURL(formValues[key].value),
        };
    }
  return images;
};
