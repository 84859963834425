export const swapArrElements = (arr, x, y) => {
  var b = arr[x];
  arr[x] = arr[y];
  arr[y] = b;
  return arr;
};

// compares origin game with updated gameForm
// returns updated values only
export const getUpdatedValues = (game, gameFormValues) => {
  const updatedValues = {};
  Object.keys(gameFormValues).forEach((key) => {
    if (game[key] !== gameFormValues[key]) {
      if (key === "gameUniverseId") {
        if (game.universe.id !== gameFormValues.gameUniverseId)
          updatedValues[key] = gameFormValues.gameUniverseId;
      } else {
        console.log("field changed: ", key);
        console.log({
          initialField: game[key],
          updatedField: gameFormValues[key],
        });
        updatedValues[key] = gameFormValues[key];
      }
    }
  });
  return updatedValues;
};

export const areGameQuestionsUpdated = (game, questions) => {
  console.log({ game, questions });
  if (game.questions.length !== questions.length) return true;
  for (let i = 0; i < questions.length; i++) {
    for (const key of Object.keys(questions[i])) {
      if (key === "answers") {
        if (game.questions[i].answers.length !== questions[i].answers.length)
          return true;
        for (let j = 0; j < questions[i].answers.length; j++) {
          for (const answerKey of Object.keys(questions[i].answers[j])) {
            // console.log({
            //   game: game.questions[i].answers[j][answerKey],
            //   form: questions[i].answers[j][answerKey],
            // });
            if (
              game.questions[i].answers[j][answerKey] !==
              questions[i].answers[j][answerKey]
            ) {
              // console.log({ answerKey });
              return true;
            }
          }
        }
      } else if (key === "assetType") {
        const assetType = questions[i].assetType;
        if (game.questions[i][assetType] !== questions[i][assetType])
          return true;
        if (
          assetType !== "none" &&
          game.questions[i][assetType].s3key &&
          questions[i][assetType].s3key &&
          game.questions[i][assetType].s3key !== questions[i][assetType].s3key
        )
          return true;
        if (
          assetType !== "none" &&
          game.questions[i][assetType].url &&
          questions[i][assetType].url &&
          game.questions[i][assetType].url !== questions[i][assetType].url
        ) {
          return true;
        }
      } else {
        if (
          game.questions[i][key] &&
          game.questions[i][key] !== questions[i][key]
        ) {
          console.log({ key });
          return true;
        }
      }
    }
  }
  return false;
};

export const isInventoryUpdated = (game, inventory) => {
  if (game.inventory.length !== inventory.length) return true;
  for (let i = 0; i < inventory.length; i++) {
    for (const key of Object.keys(inventory[i])) {
      if (game.inventory[i][key] !== inventory[i][key]) {
        console.log({ key });
        return true;
      }
    }
  }
  return false;
};
